/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	17-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_init = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {
		
		/**
		Unbind: Unnecessary listeners
		**/
		//$(document).off('click.cookieWallSubmit');
		//$(document).off('click.cookieWallTabs');
		//$(document).off('click.cookieBarSubmit');

		/*
		Close the mobilemenu always on route
		*/
		specific_mobilemenu.toggle(1);
	
		/*
		Trigger: Instagram
		*/
		//specific_instagram.onready('thumb',8,true);

		/*
		Trigger: Facebook
		*/
		//specific_facebook.onready(6,true);
		
		/*
		Catch and active URL
		*/
		var _url_active_main = './';
		
		$('.js-nav-item').removeClass('active');

		if(url[1] !== '') { 
			_url_active_main = url[1];
		}
		
		if(_url_active_main !== '') {
			$('.js-nav-item[data-go-url="' + _url_active_main + '"]').addClass('active');
		}

		/*
		What can you do here: Mouse over
		*/
		$('.js-images-todo-button').on('mouseover',function() {

			$('.js-images-todo').find('div').removeClass('active');
			$('.js-images-todo-button').removeClass('active');

			$('.js-images-todo').find('[data-todo=' + $(this).data('todo') + ']').addClass('active');
			$(this).addClass('active');

		});

		/*
		Directly scroll
		*/
		var _page_home		= 0;
		var _page_gallery	= $('.block.gallery').offset().top - 100;
		var _page_prijzen	= $('.block.prijzen').offset().top - 100;
		// var _page_tedoen	= $('.block.tedoen').offset().top;
		var _page_contact	= $('.block.contact').offset().top;

		if(
			url[1] === ''
		) {
			
			$('html, body').stop().animate({
				scrollTop: _page_home
			}, 1000);
					
		} else {
			
			switch(url[1]) {
				
				default:
					$('html, body').stop().animate({
						scrollTop: _page_home
					}, 1000,
					'easeOutQuint');
				break;
				
				case 'fotogalerij':
					$('html, body').stop().animate({
						scrollTop: _page_gallery
					}, 1000,
					'easeOutQuint');
				break;

				case 'kamers-en-prijzen':
					$('html, body').stop().animate({
						scrollTop: _page_prijzen
					}, 1000,
					'easeOutQuint');
				break;

				case 'wat-is-er-te-doen':
					$('html, body').stop().animate({
						scrollTop: _page_tedoen
					}, 1000,
					'easeOutQuint');
				break;

				case 'contact':
					$('html, body').stop().animate({
						scrollTop: _page_contact
					}, 1000,
					'easeOutQuint');
				break;
				
			}
			
		}

	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	// none
	
}).apply(specific_interactions_init); 
