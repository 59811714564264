/*
Version 0.110

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	08-03-2019
	- [CHANGE] Added variable check to set height and with when not given in on windowResize();
	11-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_window = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	// none
	
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
		/**
		Window: resize
		**/
		this.windowResize = function(scrolled,h,w) {
			
			/*
			Set: Variables
			*/
			if(typeof h === 'undefined') {
				h = $(window).height();
			}
			if(typeof w === 'undefined') {
				w = $(window).width();
			}
			
			if(w < 500) { 
				$('.js-spotlight').height('auto'); 
			} else {
				$('.js-spotlight').height(h); 
			}

			document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');

		};
		
		/**
		Window: scroll
		**/
		this.windowScroll = function(scrolled,h,w) {

			var _page_home		= 0;
			var _page_gallery	= $('.block.gallery').offset().top - (h/2);
			var _page_prijzen	= $('.block.prijzen').offset().top - (h/2);
			// var _page_tedoen	= $('.block.tedoen').offset().top - (h/2);
			var _page_contact	= $('.block.contact').offset().top - (h/2);
		
			/*
			Navigation
			*/
			if(scrolled > 10) {
				$('header').addClass('show');
			} else {
				$('header').removeClass('show');
			}

			/*
			Select: Menu
			*/
			if(scrolled == _page_home || scrolled > _page_home) {
				if(_active_page !== 0) {
					
					$('.js-scroll-nav').removeClass('active');
					$('.js-scroll-nav[data-go-url="."]').addClass('active');

					_active_page = 0;
				}
			}
			if(scrolled >= _page_gallery) {
				if(_active_page !== 1) {
					
					$('.js-scroll-nav').removeClass('active');
					$('.js-scroll-nav[data-go-url="fotogalerij"]').addClass('active');

					_active_page = 1;
				}
			}
			if(scrolled >= _page_prijzen) {
				if(_active_page !== 2) {
					
					$('.js-scroll-nav').removeClass('active');
					$('.js-scroll-nav[data-go-url="kamers-en-prijzen"]').addClass('active');

					_active_page = 2;
				}
			}
			// if(scrolled >= _page_tedoen) {
			// 	if(_active_page !== 3) {
					
			// 		$('.js-scroll-nav').removeClass('active');
			// 		$('.js-scroll-nav[data-go-url="wat-is-er-te-doen"]').addClass('active');

			// 		_active_page = 3;
			// 	}
			// }
			if(scrolled >= _page_contact) {
				if(_active_page !== 4) {
					
					$('.js-scroll-nav').removeClass('active');
					$('.js-scroll-nav[data-go-url="contact"]').addClass('active');

					_active_page = 4;
				}
			}

		};
		
		
		/**
		Window: orientation
		**/
		this.windowOrientationChange = function() {
			
		};
		
}).apply(specific_interactions_window); 