/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	17-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_firstload = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {
		
		/*
		Trigger: modal and popup preloader
		*/
		images.preload();
		modal.onready();
		popup.onready();
		form.onready();
		if(pollConnection === 1) { poll_connection.onready(); }
		
		/*
		Trigger: onready classes
		*/
		specific_mobilemenu.onready();

		/*
		Google analytics
		*/
		var queryString 	= window.location.search;
		var urlParams 		= new URLSearchParams(queryString);
		var utm_source 		= urlParams.get('bnr');

		if(utm_source == 'fa1') {

			googleanalytics.track(
				'event',
				'Fantastisch Oostenrijk banner klein',
				'Klik',
				'Klik',
				1
			);
		
		}

		if(utm_source == 'fa2') {

			googleanalytics.track(
				'event',
				'Fantastisch Oostenrijk banner groot',
				'Klik',
				'Klik',
				1
			);
		
		}
		

		/*
		Swiper
		*/
		var swiper1 = new Swiper('.js-swiper-1', {

		  loop: true,

		  navigation: {
		    nextEl: '.button-next',
		    prevEl: '.button-prev',
		  },		 

		  autoplay: {
		    delay: 1500,
		  },

		  disableOnInteraction:true

		});

		/*
		Swiper
		*/
		var swiper2 = new Swiper('.js-swiper-2', {

		  loop: true,

		  navigation: {
		    nextEl: '.button-next',
		    prevEl: '.button-prev',
		  },

		  slidesPerView :3,
		  spaceBetween:20,

		  autoplay: {
		    delay: 1500,
		  },

		  disableOnInteraction:true,

		  breakpoints: {
		    
		    500: {
		      slidesPerView: 1,
		      spaceBetween: 20
		    },
		    750: {
		      slidesPerView: 2,
		      spaceBetween: 20
		    }

		  }

		});

		/*
		Room: Interaction
		*/
		specific_room.onready();

		/*
		Popup
		*/
		if(
			url[1] == 'kamers-en-prijzen' &&
			url[2] !== ''
		) {
			$('.js-room-popup-open-' + url[2]).click();
		}

		/*
		MEWS
		*/
	    Mews.Distributor({
	        configurationIds: [
	            '146bf985-ef46-484c-a32c-aef30098c59d',
	        ],
	        openElements: '.distributor',
	    });
		
	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	// none
	
}).apply(specific_interactions_firstload); 