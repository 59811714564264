/*
Version 0.110

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	08-03-2019
	- [CHANGE] Added variable check to set height and with when not given in on windowResize();
	11-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_room = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	// none
	
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************

	this.onready = function() {

		/*
		Open: Room popup
		*/
		$(document).on('click','.js-room-popup-open',function() {
			
			var _room_id 			= $(this).data('room');
			var _room_smoobu_id 	= $(this).data('room-smoobu');
			var _room_title			= $(this).data('room-title');
			var _room_subtitle		= $(this).data('room-subtitle');

			specific_room.open(
				_room_smoobu_id,
				_room_title,
				_room_subtitle
			);

			/*
			Update URL
			*/
			window.history.pushState(
				'',
				'New URL: ' + globalUrl + '/kamers-en-prijzen/' + _room_id,
				globalUrl + '/kamers-en-prijzen/' + _room_id
			);

		});

		/*
		Close
		*/
		$(document).on('click','.js-room-popup-exit',function() {
			specific_room.close();
		});

	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	/*
	Open
	*/
	this.open = function(
		_room_smoobu_id,
		_room_title,
		_room_subtitle
	) {

		$('body').css({
			'overflow':'hidden'
		});

		$('.js-room-popup').fadeIn();

		/*
		Load: Smoobu
		*/
		_str = '<div id="apartmentIframe' + _room_smoobu_id + '">';
        	_str += '<script>BookingToolIframe.initialize({"url": "https://login.smoobu.com/en/booking-tool/iframe/197001/' + _room_smoobu_id + '", "baseUrl": "https://login.smoobu.com", "target": "#apartmentIframe' + _room_smoobu_id + '"})</script>';
        _str += '</div>';

        /*
        Set
        */
        $('.js-set-room-title').html(_room_title);
        $('.js-set-room-subtitle').html(_room_subtitle);

        // setTimeout(function() {
        	$('.js-set-smoobu').html(_str);
    	// },1000);

	};

	/*
	Close
	*/
	this.close = function() {

		$('.js-room-popup').fadeOut();

		$('body').css({
			'overflow':'auto'
		});

		$('.js-set-smoobu').html('');

	};
		
}).apply(specific_room); 